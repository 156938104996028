// src/components/header.js
import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="text-white bg-[#383e3f] px-6 pt-6 pb-3 bottom-0">
      <div className="max-w-4xl mx-auto">
        <div className="text-xl pb-1">STARKS</div>
        <div className="text-xs mb-4">MAKE STARS</div>

        <div className="text-center">
          <div className="flex justify-center flex-wrap pb-3">
            <div className="w-1/2 pb-1">
              <Link to="/#top">Top</Link>
            </div>
            <div className="w-1/2 pb-1">
              <Link to="/news">News</Link>
            </div>
            <div className="w-1/2 pb-1">
              <Link to="/about">About Us</Link>
            </div>
            <div className="w-1/2 pb-1">
              <Link to="/service">Service</Link>
            </div>
            <div className="w-1/2 pb-1">
              <Link to="/contact">Contact</Link>
            </div>
            <div className="w-1/2 pb-1">
              <Link to="/privacy">Privacy</Link>
            </div>
          </div>
        </div>
        <div className="text-sm text-center pt-2">
          <div>STARKSは古物営業法に基づく古物商の許可を得ております。</div>
          <div>愛知県公安委員会許可 第541422006200号</div>
        </div>

        <div className="text-center text-sm pt-2">
          © {new Date().getFullYear()} STARKS inc. All Rights Reserved.
        </div>
      </div>
    </footer>
  )
}
