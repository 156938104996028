// components/seo.js

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  title,
  description,
  image,
  ogpTitle,
  ogpDescription,
  ogpSiteName,
  robots,
}) {
  const { pathname } = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            author
            siteUrl
            defaultImage: image
            lang
            defaultSiteName: siteName
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    defaultDescription,
    author,
    siteUrl,
    defaultImage,
    lang,
    defaultSiteName,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    ogpTitle: ogpTitle || title || defaultTitle,
    ogpDescription: ogpDescription || description || defaultDescription,
    ogpSiteName: ogpSiteName || defaultSiteName,
    robots: robots,
  }

  return (
    <Helmet>
      <title>{seo.title}</title>
      <html lang={lang} />
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta name="robots" content={seo.robots} />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />

      <meta name="description" content={seo.description} />
      <meta name="author" content={author} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.ogpTitle} />
      <meta property="og:description" content={seo.ogpDescription} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content={seo.ogpSiteName} />
      <meta property="og:locale" content="ja_JP" />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:creator" content={twitterUsername} /> */}
      <meta name="twitter:title" content={seo.ogpTitle} />
      <meta name="twitter:description" content={seo.ogpDescription} />
      <meta name="twitter:image" content={seo.image} />
      <script src="https://cdn.jsdelivr.net/npm/tw-elements/dist/js/index.min.js"></script>
    </Helmet>
  )
}

Seo.propTypes = {
  Title: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  Image: PropTypes.string,
  OgpTitle: PropTypes.string,
  OgpDescription: PropTypes.string,
  OgpSiteName: PropTypes.string,
  Robots: PropTypes.string,
}

Seo.defaultProps = {
  Title: "",
  Description: "",
  Image: null,
  OgpTitle: null,
  OgpDescription: null,
  OgpSiteName: null,
  Robots: "index follow",
}

export default Seo
