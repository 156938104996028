// src/components/header.js
import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Header({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <header className="sticky top-0 bg-white shadow z-10">
      <nav className="flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg shadow">
        <div className="container mx-auto flex flex-wrap items-center justify-between animate-fade-in-up">
          <div className="w-full flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="leading-relaxed inline-block mr-4 whitespace-no-wrap"
              to="/"
            >
              <div className="flex items-center text-lg self-center">
                <StaticImage
                  src="../images/favicon.png"
                  className="h-8 w-8 mr-2 md:h-10 md:w-10"
                />
                STARKS
              </div>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item px-3 py-2 flex items-center hover:opacity-75">
                <Link to="/news">News</Link>
              </li>
              <li className="nav-item px-3 py-2 flex items-center hover:opacity-75">
                <Link to="/about">About Us</Link>
              </li>
              <li className="nav-item px-3 py-2 flex items-center hover:opacity-75">
                <Link to="/service">Service</Link>
              </li>
              <li className="nav-item px-3 py-2 flex items-center hover:opacity-75">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
